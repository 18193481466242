<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        优惠券管理
      </template>
      <template #input>
        <a-input placeholder="请输入优惠券名称" v-model="name" allowClear/>
        <a-button :loading="loading" type="primary" @click="onSearch()" v-hasPermi="['coupon:list:query']">搜索</a-button>
        <a-button style="margin-left:20px" @click="goDetail(1)">新建</a-button>
      </template>
    </HeaderBox>
    <a-table
      class="table-template"
      :rowKey="item => item.couponId"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }" @change="onPage"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="couponPrice" slot-scope="price">
        ￥{{ price }}
      </template>
      <template slot="state" slot-scope="text">
        <span :class="text == '已启用' ? 'state_color_green' : text == '未上架' ? 'state_color_yellow' : 'state_color_red'">{{ text }}</span>
      </template>
      <template slot="volumes" slot-scope="item,row">
        {{row.noVolumes ? row.volumes : '不限量'}}
      </template>
      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="goDetail(2,row)" v-hasPermi="['coupon:info:edit']">编辑 | </span>
          <span @click="goRecord(row)" v-hasPermi="['coupon:users:query']">领取用户 | </span>
          <span @click="onUpdown(row)" v-hasPermi="['coupon:info:edit']">{{row.state == '已启用'? '下架' : '上架'}}</span>
          <span v-show="row.drawnum == 0" @click="onDelete(row.couponId)" v-hasPermi="['coupon:info:delete']"> | 删除</span> 
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "优惠券名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "领取方式",
    align: "center",
    dataIndex: "astrict",
  },
  {
    title: "领取时间",
    align: "center",
    dataIndex: "drawTime",
  },
  {
    title: "优惠金额",
    align: "center",
    dataIndex: "couponPrice",
    scopedSlots: {
      customRender: "couponPrice",
    },
  },
  {
    title: "优惠券数量",
    align: "center",
    scopedSlots: {
      customRender: "volumes",
    },
  },
  {
    title: "领取数量",
    align: "center",
    dataIndex: "drawnum",
  },
  {
    title: "核销数量",
    align: "center",
    dataIndex: "usenum",
  },
  {
    title: "已作废数量",
    align: "center",
    dataIndex: "cancelnum",
  },
  {
    title: "上架状态",
    align: "center",
    dataIndex: "state",
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  }
]
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      total:0,
      name: '',
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      loading: false,
      tableData:[]
    }
  },
  // 事件处理器
  methods: {
    onPage(e){
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    onSearch(){
      this.pageNumber = 1;
      this.getList();
    },

    // 查询列表
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/new-coupon/list",
        params: {
          name: this.name,
          page: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },


    // 删除
    onDelete(id) {
      this.$confirm({
        title: "确定删除吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/new-coupon/del?id=" + id,
            method: "POST",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },

    onUpdown(e){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/new-coupon/edit',
        method: 'PUT',
        params: {
          state: e.state == '已启用' ? 3 : 2,
          couponId: e.couponId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((e.state == '已启用' ? '下架' : '上架') + '成功')
          this.getList()
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      });
    },

    // 领取用户记录
    goRecord(e){
      this.$router.push(
        "/admin/PromotionManage/collectingUsers?couponId="+ e.couponId
      );
    },

    // 优惠券新建/编辑
    goDetail(type,e){
      this.$router.push({
        path: "/admin/PromotionManage/newCouponDetail",
        query: { 
          operation: type,
          id: e ? e.couponId : undefined,
          drawnum: e ? e.drawnum : undefined,
        },
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
